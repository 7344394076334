<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as SUPPLIER_ORG_TYPES } from './store'
import { ROUTES as SUPPLIER_ORG_ROUTES } from './router'
import { ROUTES as LEAD_ORG_ROUTES } from '../lead-organization/router'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../customer-organization/router'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE } from '@/const'

import OrganizationDetailMixin from '../../../components/organization/mixins/OrganizationDetailMixin'
import SupplierContactList from '../../../contact/contact/supplier-contact/SupplierContactList'

export default {
  name: 'SupplierOrganizationDetail',
  mixins: [OrganizationDetailMixin],
  components: {
    'component-contact-list': SupplierContactList
  },
  data () {
    return {
      SUPPLIER_ORG_ROUTES,
      title: 'Supplier organizations',
      routeNameList: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_LIST,
      routeDocumentNameList: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DOCUMENTS,
      orgType: ORGANIZATION_SUPPLIER_TYPE_CODE,
      showDownloadDocumentsButton: true,
      showCompanyRegistryButton: true,
      routeOrganizationInspectionList: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_INSPECTIONS_LIST
    }
  },
  computed: {
    ...mapGetters({
      ...SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.DETAIL.GETTERS,
      groups: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.groups,
      organizationform: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.organizationform,
      typologyChoices: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.typologyChoices,
      employeeCategoryChoices: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.employeeCategoryChoices,
      orgTypeChoices: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.orgTypeChoices
    })
  },
  methods: {
    ...mapMutations({
      setGroups:
        SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.MUTATIONS.setGroups
    }),
    ...mapActions({
      ...SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.DETAIL.ACTIONS,
      getOrganizationList: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS.getList,
      getGroups: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS.getGroups,
      getOrganizationform: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS.getOrganizationform,
      getTypologychoices: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS.getTypologychoices,
      getEmployeecategorychoices: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS.getEmployeecategorychoices,
      getOrgtypechoices: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS.getOrgtypechoices
    }),
    goToDetailPage (evt) {
      this.orgFilters = false
      this.showDuplicatedForm = false
      if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_LEAD_CODE)) {
        this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_SUPPLIER_CODE } })
      }
    }
  }
}
</script>
